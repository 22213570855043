var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"leaveformModal",attrs:{"id":"modal-center","centered":"","title":_vm.$t("leave.newApplication"),"title-class":"font-18","hide-footer":""},on:{"hidden":_vm.handleClose}},[_c('form',{staticClass:"needs-validation"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-3"},[_vm._v(" "+_vm._s(_vm.$t("leave.leaveType"))+" ")]),_c('div',{staticClass:"col-md-8 mb-3"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.leaveType),expression:"form.leaveType"}],staticClass:"form-select",class:{
                'is-invalid': _vm.submitted && _vm.$v.form.leaveType.$error,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "leaveType", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.lookupData.leaveType),function(item,index){return _c('option',{key:index,domProps:{"value":item.value}},[_vm._v(_vm._s(item.label))])}),0),(_vm.submitted && _vm.$v.form.leaveType.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.leaveType.required)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("common.requiredError"))+". ")]):_vm._e()]):_vm._e()])]),(false)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-3"},[_vm._v(" "+_vm._s(_vm.$t("leave.annualLeave"))+" ")]),_c('div',{staticClass:"col-md-8 mb-3"},[_vm._v(" "+_vm._s(_vm.$t("leave.validAnnualLeave"))+":"+_vm._s(_vm.annualLeave-_vm.days)+" ")])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-3"},[_vm._v(" "+_vm._s(_vm.$t("leave.startDate"))+" ")]),_c('div',{staticClass:"col-md-8 mb-3"},[_c('date-picker',{class:{
                              'is-invalid': _vm.submitted && _vm.$v.form.startDate.$error,
            },attrs:{"format":"YYYY-MM-DD HH:mm:ss","value-type":"format","first-day-of-week":1,"lang":"en","type":"datetime","hour-options":_vm.hours,"minute-options":_vm.minutes,"second-options":_vm.seconds},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}}),(_vm.submitted && _vm.$v.form.startDate.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.startDate.required)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("common.requiredError"))+". ")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-3"},[_vm._v(" "+_vm._s(_vm.$t("leave.endDate"))+" ")]),_c('div',{staticClass:"col-md-8 mb-3"},[_c('date-picker',{class:{
                              'is-invalid': _vm.submitted && _vm.$v.form.endDate.$error,
            },attrs:{"format":"YYYY-MM-DD HH:mm:ss","value-type":"format","first-day-of-week":1,"lang":"en","type":"datetime","hour-options":_vm.hours,"minute-options":_vm.minutes,"second-options":_vm.seconds},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}}),(_vm.submitted && _vm.$v.form.endDate.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.endDate.checkDate)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("leave.dateError"))+". ")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-3"},[_vm._v(" "+_vm._s(_vm.$t("leave.reason"))+" ")]),_c('div',{staticClass:"col-md-8 mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.reason),expression:"form.reason"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.reason.$error,
                },attrs:{"type":"text"},domProps:{"value":(_vm.form.reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "reason", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.reason.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.reason.required)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("common.requiredError"))+". ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-3"},[_vm._v(" "+_vm._s(_vm.$t("leave.attachment"))+" ")]),_c('div',{staticClass:"col-md-5 mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fileOrgName),expression:"form.fileOrgName"}],staticClass:"form-control",class:{
                'is-invalid': _vm.submitted && _vm.$v.form.fileOrgName.$error,
            },attrs:{"type":"text","disabled":""},domProps:{"value":(_vm.form.fileOrgName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "fileOrgName", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.fileOrgName.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.fileOrgName.checkFile)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("common.requiredError"))+". ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"col-md-3 mb-3"},[_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":_vm.handleSaveFormFile}},[_vm._v(_vm._s(_vm.$t("common.selectFile")))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-3"},[_vm._v(" "+_vm._s(_vm.$t("leave.approver"))+" ")]),_c('div',{staticClass:"col-md-8 mb-3"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.approver),expression:"form.approver"}],staticClass:"form-select",class:{
                'is-invalid': _vm.submitted && _vm.$v.form.approver.$error,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "approver", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"jack","selected":""}},[_vm._v("Jack Duan")])]),(_vm.submitted && _vm.$v.form.approver.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.approver.required)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("common.requiredError"))+". ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-3"},[_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleSave(1)}}},[_vm._v(_vm._s(_vm.$t("leave.draft")))]),_c('button',{staticClass:"btn btn-info ms-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleSave(2)}}},[_vm._v(_vm._s(_vm.$t("leave.submitApproval")))]),_c('button',{staticClass:"btn btn-light ms-1",attrs:{"type":"button"},on:{"click":_vm.handleClose}},[_vm._v(_vm._s(_vm.$t("actions.close")))])])])]),_c('input',{ref:"attachment",staticStyle:{"display":"none"},attrs:{"type":"file","aria-label":"Upload your file","placeholder":""},on:{"change":_vm.changeAttachmentFile}})])}
var staticRenderFns = []

export { render, staticRenderFns }