<script>
import Swal from "sweetalert2";
import Vue from "vue";
import DatePicker from "vue2-datepicker";
import {newLeaveApplication,getApplicationLeaveDetail,calculateLeaveHours} from '@/apis/leave'
import {uploadFile} from '@/apis/file'
//import {getStaffLeaveStatistics} from '@/apis/user'
import {lookup2,getLookupItemText} from '@/apis/common'
//import store from '@/state/store'
import {
  required
} from "vuelidate/lib/validators";
export default {
    validations: {
        form: { 
            leaveType:{ 
                required,
            },
            startDate:{ 
                required,
            },
            endDate:{ 
                checkDate(value){
                    if(value==''||value==null){
                        return false
                    }else{
                        let start = new Date(this.form.startDate)
                        let end = new Date(value)
                        if((end.getTime()-start.getTime())<1000*60*30){
                            return false
                        }else{
                            return true
                        }
                    }
                }
            },
            reason:{ 
                required,
            },
            fileOrgName:{ 
                checkFile(value){
                    if(this.form.leaveType=='SK'){
                        if(value==null||value==''){
                            return false
                        }else{
                            return true
                        }
                    }else{
                        return true
                    }
                }                
            },   
            approver:{
                required,
            }         
        },
    },
    components: { DatePicker},
    data(){
        return {
            leaveNo:null,
            submitted:false,
            annualLeave:0,
            days:0,
            leaveTypes:[],
            hours:[9,10,11,12,14,15,16,17,18],
            minutes:[0,30],
            seconds:[0],
            lookupRawData:{},
            lookupData:{
                leaveType:[],
                leaveStatus:[]
            },                   
            form:{
                leaveType:"",
                leaveNo:"",
                startDate:"",
                endDate:"",
                status:"",
                applicant:"",
                approver:"",
                fileOrgName:"",
                reason:"",
                days:0
            },
            params:{
                pageNum:1,
                pageSize:10,
                filter:{
                    clientName:""
                }
            }
        }
    },
    computed:{
    },
    watch: {
        '$store.state.auth.language': function() {
            this.changeLookupLanguage()
        }
    },
    created(){
        this.init()
    },
    methods:{
        show(leaveNo){
            if(leaveNo!=null){
                getApplicationLeaveDetail(leaveNo).then((res)=>{
                    this.form = res.data
                    this.$refs.leaveformModal.show()
                })
            }else{
                this.$refs.leaveformModal.show()                
            }
        },
        handleSave(type){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            } else {
                if(type=="1"){
                    //草稿
                    this.form.status = "D"
                }else{
                    this.form.status = "PA"
                }
                this.form.days = calculateLeaveHours(this.form.startDate,this.form.endDate)/8;
                newLeaveApplication(this.form).then(()=>{
                    this.$emit('update')
                    this.$refs.leaveformModal.hide() 
                })
            }
        },
        handleCreateClient(){
            Swal.fire({
                title: this.$t("order.infoSure"),
                text: this.$t("order.infoCreateClient"),
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("actions.ok"),
                cancelButtonText: this.$t("actions.cancel")
            }).then(result => {
                if (result.value) {
                    Vue.swal(this.$t("order.errorCreateFail"));
                }
            });

        },
        handleClose(){
            this.$refs.leaveformModal.hide() 
        },
        handleSaveFormFile(){
            this.$refs.attachment.click()
        },
        changeAttachmentFile(){
            if(this.$refs.attachment.files.length==1){
                const formData = new FormData();
                formData.append("files",this.$refs.attachment.files[0]);
                uploadFile(formData).then((res)=>{
                    this.form.fileOrgName = res.data[0].fileOrgName
                })
            }
        },     
        async init(){
            //getStaffLeaveStatistics({userName:store.state.auth.currentUser.userName}).then((res)=>{
            //    this.annualLeave = res.data.annual_leave;
            //    this.days = res.data.days;
            //})
            this.lookupRawData = await lookup2("LEAVE_STATUS,LEAVE_TYPE");
            this.lookupData.leaveType = this.lookupRawData["LEAVE_TYPE"].map(item=>{return {...item,labelCn:item.label}});
            this.lookupData.leaveStatus = this.lookupRawData["LEAVE_STATUS"].map(item=>{return {...item,labelCn:item.label}});
            this.changeLookupLanguage()
        },
        changeLookupLanguage(){
            if(localStorage.getItem('language')=="zh-CN"){
                this.lookupData.leaveType = this.lookupData.leaveType.map(item=>{return {...item,label:item.labelCn}});
                this.lookupData.leaveStatus = this.lookupData.leaveStatus.map(item=>{return {...item,label:item.labelCn}});
            }else{
                this.lookupData.leaveType = this.lookupData.leaveType.map(item=>{return {...item,label:item.labelEn}});
                this.lookupData.leaveStatus = this.lookupData.leaveStatus.map(item=>{return {...item,label:item.labelCn}});
            }
        },   
        formatLookupText(typeCode,value){
            return getLookupItemText(this.lookupRawData[typeCode],value);
        },              
    }
};
</script>
<template>
    <b-modal
    id="modal-center"
    ref="leaveformModal"
    centered
    :title='$t("leave.newApplication")'
    title-class="font-18"
    hide-footer
    @hidden="handleClose"
    >
    <form class="needs-validation">
        <div class="row">
            <div class="col-md-4 mb-3">
                {{$t("leave.leaveType")}}
            </div>            
            <div class="col-md-8 mb-3">
                <select class="form-select" v-model="form.leaveType"
                :class="{
                    'is-invalid': submitted && $v.form.leaveType.$error,
                }"                        
                >
                    <option v-for="(item,index) in lookupData.leaveType" :value="item.value" :key="index">{{item.label}}</option>          
                </select>
                <div
                    v-if="submitted && $v.form.leaveType.$error"
                    class="invalid-feedback"
                    >
                        <span v-if="!$v.form.leaveType.required">
                            {{$t("common.requiredError")}}.
                        </span>
                </div>                          
            </div>        
        </div>
        <div class="row" v-if="false">
            <div class="col-md-4 mb-3">
                {{$t("leave.annualLeave")}}
            </div>            
            <div class="col-md-8 mb-3">
                {{$t("leave.validAnnualLeave")}}:{{ annualLeave-days }}
            </div>      
        </div>
        <div class="row">
            <div class="col-md-4 mb-3">
                {{$t("leave.startDate")}}
            </div>
            <div class="col-md-8 mb-3">
                <date-picker
                  format="YYYY-MM-DD HH:mm:ss"
                  value-type="format"
                  :first-day-of-week="1"
                    v-model="form.startDate"
                  lang="en"
                  type="datetime"
                  :hour-options="hours"
                  :minute-options="minutes"
                  :second-options="seconds"
                  :class="{
                                  'is-invalid': submitted && $v.form.startDate.$error,
                }"                  
                ></date-picker>
                <div
                    v-if="submitted && $v.form.startDate.$error"
                    class="invalid-feedback"
                    >
                        <span v-if="!$v.form.startDate.required">
                            {{$t("common.requiredError")}}.
                        </span>
                </div>          
            </div>                                
        </div>
        <div class="row">
            <div class="col-md-4 mb-3">
                {{$t("leave.endDate")}}
            </div>
            <div class="col-md-8 mb-3">
                <date-picker
                  format="YYYY-MM-DD HH:mm:ss"
                  value-type="format"
                  :first-day-of-week="1"
                    v-model="form.endDate"
                  lang="en"
                  type="datetime"
                  :hour-options="hours"
                  :minute-options="minutes"
                  :second-options="seconds"
                  :class="{
                                  'is-invalid': submitted && $v.form.endDate.$error,
                }"                  
                ></date-picker>
                <div
                    v-if="submitted && $v.form.endDate.$error"
                    class="invalid-feedback"
                    >
                        <span v-if="!$v.form.endDate.checkDate">
                            {{$t("leave.dateError")}}.
                        </span>                        
                        
                </div>                
            </div>                                
        </div>        
        <div class="row">
                <div class="col-md-4 mb-3">
                    {{$t("leave.reason")}}
                </div>            
                <div class="col-md-8 mb-3">
                    <input type="text" class="form-control"  v-model="form.reason" 
                    :class="{
                        'is-invalid': submitted && $v.form.reason.$error,
                    }"                    
                    />
                    <div
                        v-if="submitted && $v.form.reason.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.form.reason.required">
                                {{$t("common.requiredError")}}.
                            </span>
                    </div>                    
                </div>
        </div>        
        <div class="row">
            <div class="col-md-4 mb-3">
                {{$t("leave.attachment")}}
            </div>            
            <div class="col-md-5 mb-3">
                <input type="text" class="form-control"  v-model="form.fileOrgName" disabled 
                :class="{
                    'is-invalid': submitted && $v.form.fileOrgName.$error,
                }"                    
                />
                <div
                    v-if="submitted && $v.form.fileOrgName.$error"
                    class="invalid-feedback"
                    >
                        <span v-if="!$v.form.fileOrgName.checkFile">
                            {{$t("common.requiredError")}}.
                        </span>
                </div>       
        </div>                
            <div class="col-md-3 mb-3">
                <button type="button" class="btn btn-info" @click="handleSaveFormFile">{{$t("common.selectFile")}}</button> 
            </div>    
        </div>
        <div class="row">
            <div class="col-md-4 mb-3">
                {{$t("leave.approver")}}
            </div>            
            <div class="col-md-8 mb-3">
                <select class="form-select" v-model="form.approver"
                :class="{
                    'is-invalid': submitted && $v.form.approver.$error,
                }"                        
                >
                    <option value="jack" selected>Jack Duan</option>          
                </select>
                <div
                    v-if="submitted && $v.form.approver.$error"
                    class="invalid-feedback"
                    >
                        <span v-if="!$v.form.approver.required">
                            {{$t("common.requiredError")}}.
                        </span>
                </div>                          
            </div>        
        </div>        
        <div class="row">                  
                <div class="col-md-12 mb-3">
                    <button type="button" class="btn btn-info" @click="handleSave(1)">{{$t("leave.draft")}}</button>
                    <button type="button" class="btn btn-info ms-1" @click="handleSave(2)">{{$t("leave.submitApproval")}}</button>
                    <button type="button" class="btn btn-light ms-1" @click="handleClose">{{$t("actions.close")}}</button>
                </div>        
        </div>
        </form>    
        <input ref="attachment" type="file" aria-label="Upload your file" placeholder="" style="display: none;" @change="changeAttachmentFile">  
    </b-modal>
</template>
<style lang="scss">
.is-invalid .mx-input-wrapper .mx-input{
    border: 1px solid #ec4561 !important;
  }
  .is-invalid .mx-input-wrapper .mx-icon-calendar{
    color: #ec4561 !important;
  }
  .is-invalid .multiselect__tags{
    border:1px solid #ec4561 !important;
  }
  .username::first-letter {
    text-transform: uppercase;
}  
</style>
