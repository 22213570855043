<script>
import simplebar from "simplebar-vue"
import {lookup2,getLookupItemText} from '@/apis/common'
import {queryMyLeaveRecord} from '@/apis/leave'
export default {
  components: {simplebar},
  data() {
    return {
      tableData:[],
      curData:{},
      lookupRawData:{},
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],      
      pageRequest:{
          pageNum:1,
          pageSize:10
      },
      filter:{
          leaveNo:"",
          createDate:"",
      },            
      lookupData:{
        leaveType:[],
        leaveStatus:[]
      },            
    };
  },
  computed:{
  },
  watch: {
    '$store.state.auth.language': function() {
        this.changeLookupLanguage()
    },
    "pageRequest.pageNum"() {
            this.loadData()
        },
        filter:{
            handler(){
                this.loadData();
            },
            deep:true
        }    
  },
  created(){
    this.init()
  },
  mounted() {
  },
  methods: {
    async init(){
        this.lookupRawData = await lookup2("LEAVE_STATUS,LEAVE_TYPE");
        this.lookupData.leaveType = this.lookupRawData["LEAVE_TYPE"].map(item=>{return {...item,labelCn:item.label}});
        this.lookupData.leaveStatus = this.lookupRawData["LEAVE_STATUS"].map(item=>{return {...item,labelCn:item.label}});
        this.changeLookupLanguage()
        this.loadData()
    },
    changeLookupLanguage(){
        if(localStorage.getItem('language')=="zh-CN"){
            this.lookupData.leaveType = this.lookupData.leaveType.map(item=>{return {...item,label:item.labelCn}});
            this.lookupData.leaveStatus = this.lookupData.leaveStatus.map(item=>{return {...item,label:item.labelCn}});
        }else{
            this.lookupData.leaveType = this.lookupData.leaveType.map(item=>{return {...item,label:item.labelEn}});
            this.lookupData.leaveStatus = this.lookupData.leaveStatus.map(item=>{return {...item,label:item.labelCn}});
        }
    },   
    formatLookupText(typeCode,value){
        return getLookupItemText(this.lookupRawData[typeCode],value);
    },  
    getParams(){
        let params = {...this.pageRequest,filter:this.filter}
        return params
    },
    loadData(){
        const params = this.getParams();
        queryMyLeaveRecord(2,params).then((res)=>{
            this.tableData = res.data;
            this.totalRows = res.page.total;
        });
    },        
    handleDetail(item){
      this.curData = item
      this.$emit('detail',item)
    }
  }
};
</script>

<template>
  <div class="mb-0">
    <div class="row">
      <div class="table-responsive">
          <simplebar class="h-100">
              <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                      <tr>
                          <th scope="col">{{$t("leave.applicant")}}</th>
                          <th scope="col">{{$t("leave.startDate")}}</th>
                          <th scope="col">{{$t("leave.endDate")}}</th>
                          <th scope="col">{{$t("leave.leaveType")}}</th>
                          <th scope="col">{{$t("leave.status")}}</th>
                          <th scope="col">{{$t("common.createDate")}}</th>
                          <th scope="col"><a href="javascript:"  @click="loadData"><i class="ion ion-md-refresh me-1 align-middle" ></i> {{$t("actions.refresh")}}</a></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="item in tableData" :key="item.id">
                          <td class="username">{{item.applicant}}</td>
                          <td>{{item.startDate}}</td>
                          <td>{{item.endDate}}</td>
                          <td>{{formatLookupText('LEAVE_TYPE',item.leaveType)}}</td>
                          <td>{{formatLookupText('LEAVE_STATUS',item.status)}}</td>
                          <td>{{item.createDate}}</td>
                          <td>
                              <a  @click="handleDetail(item)" href="javascript:">{{$t("common.view")}}</a>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </simplebar>
      </div>
    </div>
    <div class="row g-0 text-center text-sm-start" v-if="totalRows>0">
      <div class="col-sm-6">
          <div>
              <p class="mb-sm-0">Showing {{(pageRequest.pageNum-1)*pageRequest.pageSize+1}} to {{pageRequest.pageNum*pageRequest.pageSize}} of {{totalRows}} entries</p>
          </div>
      </div>
      <!-- end col -->
      <div class="col-sm-6">
          <ul class="pagination pagination-rounded justify-content-center justify-content-sm-end mb-sm-0">
              <b-pagination v-model="pageRequest.pageNum" :total-rows="totalRows" :per-page="pageRequest.pageSize"></b-pagination>
          </ul><!-- end ul -->
      </div><!-- end col -->
  </div><!-- end row -->      
  </div>
</template>
<style lang="scss" scoped>
    .username::first-letter {
    text-transform: uppercase;
    }
</style>