<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import MyApplication from "./components/myapplication";
import MyTodo from "./components/mytodo";
import leaveform from "./components/leaveform";
export default {
  page: {
    title: "Leave Management",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout,MyApplication,leaveform,MyTodo},
  data() {
    return {
      title: "Leave Management",  
      curData:{},
    };
  },
  computed:{
  },
  created(){
  },
  mounted() {
  },
  methods: { 
    handleNewApplication(){
      this.$refs.leaveform.show()
    },
    handleDetail(item){
      if(item!=null){
        this.curData = item
        if(item.status=='D'){
          this.$refs.leaveform.show(item.leaveNo)
        }else{
          this.$router.push({
              name: 'leave-detail',
              params: {
                  leaveNo: item.leaveNo
              }
          })
        }        
      }
    },
    handleUpdate(){
      this.$refs.myapplication.loadData()      
      this.$refs.mytodo.loadData()      
    },
  }
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <h4 class="card-title">{{$t("leave.pagename")}}</h4>
              </div>
              <div class="col-sm-6">
                <div class="float-end d-none d-md-block">
                  <a href="javascript:" @click="handleNewApplication" class="btn btn-success">{{$t("leave.new")}}</a>
                </div>
              </div>
            </div>
            <b-tabs content-class="p-3">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-calendar-check"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{$t("leave.myapp")}}</span>
                </template>
                <my-application @detail="handleDetail" ref="myapplication"></my-application>
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-calendar-check"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{$t("leave.todo")}}</span>
                </template>
                <my-todo  @detail="handleDetail" ref="mytodo"></my-todo>
              </b-tab>              
            </b-tabs>
          </div>
          <leaveform ref="leaveform" @update="handleUpdate"></leaveform>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
</style>